import React from 'react'
import PropTypes from 'prop-types'
import { Consumer } from 'store/createContext'
import ChangeTitle from 'components/change-title'

const ChangeTitleContainer = ({ title }) => (
  <Consumer>
    {({ setMenuTitle }) => <ChangeTitle title={title} setMenuTitle={setMenuTitle} />}
  </Consumer>
)

ChangeTitleContainer.propTypes = {
  // children: PropTypes.node.isRequired,
}

export default ChangeTitleContainer

import React from 'react'
import { truncate } from 'lodash'

class ChangeTitle extends React.PureComponent {
  componentDidMount() {
    const { title, setMenuTitle } = this.props
    const truncatedTitle = truncate(title, {
      length: 48,
    })
    setMenuTitle(truncatedTitle)
  }

  componentDidUpdate() {
    const { title, setMenuTitle } = this.props
    const truncatedTitle = truncate(title, {
      length: 48,
    })
    setMenuTitle(title)
  }

  render() {
    return null
  }
}

export default ChangeTitle
